import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Formik, Form, Field, CccisdSelect } from 'cccisd-formik';
import { client } from 'cccisd-apollo';
import Loader from 'cccisd-loader';
import groupsQuery from './groups.graphql';
import deploymentsQuery from './deployments.graphql';
import DashboardTable from './Table/index.js';
import style from './style.css';
import MultiSelect from './MultiSelect/index.js';
const Fortress = window.cccisd.fortress;
const actingRole = Fortress.user.acting.role.handle;
const actingId = Fortress.user.acting.id;
const actingGroupId = Fortress.user.acting.group?.id;
const isNotUber = actingRole === 'guAdmin' || actingRole === 'instructor';
const roleConfig = {
    // PCANC Staff Members
    uberadmin: {
        questProjectList: [
            {
                label: 'Document Technical Assistance',
                deployments: [
                    { label: 'Site Visit: Org Info', handle: 'siteVisit' },
                    {
                        // Multi org select
                        label: 'Professional Development',
                        handle: 'profDevDoc',
                    },
                ],
            },
            {
                label: 'Feedback and Outcomes',
                deployments: [
                    {
                        // Deployment button
                        label: 'Professional Development',
                        handle: 'profDev',
                    },
                    {
                        label: 'Biannual Feedback',
                        handle: 'biannualFeedback',
                    },
                ],
            },
        ],
    },
    // "Org Admins"
    guAdmin: {
        questProjectList: [
            {
                label: 'Feedback and Outcomes',
                deployments: [{ label: 'Professional Development', handle: 'profDev' }],
            },
            {
                label: 'Document Technical Assistance',
                deployments: [
                    {
                        // Multi org select
                        label: 'Professional Development',
                        handle: 'profDevDoc',
                    },
                ],
            },
        ],
    },
    // Facilitators
    instructor: {
        questProjectList: [
            {
                label: 'Org Participant Outcomes',
                deployments: [{ label: 'Program Facilitator Form', handle: 'facilitatorForm' }],
            },
        ],
    },
};

const Dashboard = () => {
    const [projectId, setProjectId] = useState(null);
    const [groupingUnitId, setGroupingUnitId] = useState(null);
    const [deploymentId, setDeploymentId] = useState(null);
    const [selectOptions, setSelectOptions] = useState(null);
    const [deploymentInfo, setDeploymentInfo] = useState(null);
    const [metricspawn, setMetricsPawn] = useState(null);
    const [redirect, setRedirect] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        initializeFilters();
    }, []);

    useEffect(() => {
        setLoading(true);
        getDeploymentOptions(projectId);
    }, [projectId]);

    const initializeFilters = async () => {
        const response = await client.query({
            query: groupsQuery,
            fetchPolicy: 'network-only',
            variables: {},
        });

        const guList = response.data.groups.groupingUnitList.map(item => {
            return {
                value: item.group.groupId,
                label: item.group.label,
                pawnId: item.childRoles.metricspawn.pawn.pawnId,
                pawnHash: item.childRoles.metricspawn.pawn.pawnHash,
            };
        });

        const projectList = roleConfig[actingRole].questProjectList.map(item => item.label);
        const questProjectList = response.data.groups.questprojectList
            .filter(item => projectList.includes(item.group.label))
            .map(item => {
                return {
                    value: item.group.groupId,
                    label: item.group.label,
                };
            });

        setSelectOptions({ guList, questProjectList });
        setProjectId(+questProjectList[0]?.value);

        const guId = isNotUber ? actingGroupId : guList[0]?.value;

        setGroupingUnitId(guId);

        // NOTE!!! NEED metricspawn from orgAdmins group
        setMetricsPawn({ pawnId: guList[0]?.pawnId, pawnHash: guList[0]?.pawnHash });
        await getDeploymentOptions(+questProjectList[0]?.value, guList[0].label);
    };

    const getDeploymentOptions = async (groupId, orgName) => {
        const response = await client.query({
            query: deploymentsQuery,
            fetchPolicy: 'network-only',
            variables: {
                groupId: groupId ? groupId : projectId,
            },
        });

        const assignmentList = response?.data?.flows?.assignmentList;

        let deploymentList = [];

        let roleDeployments = [];

        roleConfig[actingRole].questProjectList.forEach(proj => {
            proj.deployments.forEach(dep => {
                roleDeployments.push(dep.handle);
            });
        });

        assignmentList.forEach(assign => {
            const deploymentOptions = assign.deploymentList
                .filter(dep => roleDeployments.includes(dep.deploymentHandle))
                .map(dep => {
                    return {
                        value: dep.deploymentId,
                        label: dep.label,
                        deploymentHandle: dep.deploymentHandle,
                        hash: dep.hash,
                        isOpen: dep.isOpen,
                        assignmentId: assign.assignmentId,
                    };
                });
            deploymentList = deploymentList.concat(deploymentOptions);
        });

        setSelectOptions(prevOptions => {
            return { ...prevOptions, deploymentList };
        });

        setDeploymentId(deploymentList[0]?.value);

        setDeploymentInfo({
            hash: deploymentList[0]?.hash,
            handle: deploymentList[0]?.deploymentHandle,
            isOpen: deploymentList[0]?.isOpen,
            assignmentId: deploymentList[0]?.assignmentId,
            orgName,
        });

        setLoading(false);
    };

    const getMatch = (id, type) => {
        let match;
        if (type === 'deployment') {
            match = selectOptions.deploymentList.find(item => item.value === id);
        }
        if (type === 'group') {
            match = selectOptions.guList.find(item => item.value === id);
        }
        if (match) {
            return match;
        }
    };

    const getOrgName = () => {
        if (groupingUnitId) {
            const match = selectOptions.guList.find(item => item.value === groupingUnitId);

            return match?.label;
        }
    };

    const redirectTo = url => {
        setRedirect(url);
    };

    const renderFilters = () => {
        return (
            <>
                {selectOptions && projectId && groupingUnitId && deploymentId && (
                    <Formik
                        initialValues={{
                            project: projectId,
                            organization: groupingUnitId,
                            survey: deploymentId,
                        }}
                        render={({ values, setFieldValue }) => {
                            return (
                                <Form>
                                    <div className={style.filterBar}>
                                        <Field
                                            name="project"
                                            component={CccisdSelect}
                                            label="PCANC Project"
                                            options={selectOptions.questProjectList}
                                            onChange={e => {
                                                setFieldValue('project', +e.target.value);
                                                setProjectId(+e.target.value);
                                            }}
                                        />
                                        <Field
                                            name="survey"
                                            component={CccisdSelect}
                                            label="Survey"
                                            options={selectOptions.deploymentList}
                                            onChange={e => {
                                                setFieldValue('survey', +e.target.value);
                                                setDeploymentId(+e.target.value);
                                                const match = getMatch(+e.target.value, 'deployment');
                                                setDeploymentInfo({
                                                    ...deploymentInfo,
                                                    hash: match.hash,
                                                    handle: match.deploymentHandle,
                                                    isOpen: match.isOpen,
                                                    assignmentId: match.assignmentId,
                                                });
                                            }}
                                        />
                                        {!isNotUber && deploymentInfo.handle !== 'profDevDoc' && (
                                            <Field
                                                name="organization"
                                                component={CccisdSelect}
                                                label="TA Org"
                                                options={selectOptions.guList}
                                                onChange={e => {
                                                    setFieldValue('organization', +e.target.value);
                                                    setGroupingUnitId(+e.target.value);
                                                    const match = getMatch(+e.target.value, 'group');

                                                    setDeploymentInfo({
                                                        ...deploymentInfo,
                                                        orgName: match.label,
                                                    });
                                                }}
                                            />
                                        )}
                                        {deploymentInfo.handle === 'profDevDoc' && (
                                            <Field
                                                name="multiSelect"
                                                component={MultiSelect}
                                                label="TA Org(s)"
                                                options={selectOptions.guList}
                                            />
                                        )}
                                    </div>
                                </Form>
                            );
                        }}
                    />
                )}
            </>
        );
    };

    if (loading) {
        return <Loader loading />;
    }
    if (redirect) {
        return (
            <Redirect
                to={{
                    pathname: redirect,
                    search: `?redirectUrl=${window.location.href}`,
                }}
            />
        );
    }
    // console.log({ projectId, groupingUnitId, deploymentId, d: deploymentInfo?.hash });

    return (
        <div>
            {renderFilters()}
            {projectId && groupingUnitId && deploymentId && deploymentInfo?.hash && (
                <DashboardTable
                    redirectTo={redirectTo}
                    groupingUnitId={groupingUnitId}
                    deploymentId={deploymentId}
                    deploymentInfo={deploymentInfo}
                    getOrgName={getOrgName}
                    actingId={actingId}
                    actingRole={actingRole}
                    metricsPawn={metricspawn}
                />
            )}
        </div>
    );
};

export default Dashboard;
