import React from 'react';
import style from './style.css';
const Appdefs = window.cccisd.appDefs;
const Footer = props => {
    const copyrightYear = props.copyrightYear || Appdefs?.app?.footer?.copyrightYear || new Date().getFullYear();
    return (
        <div className={style.footer}>
            <div className={style.section}>
                Copyright &copy; {copyrightYear} | Positive Childhood Alliance North Carolina | All rights reserved.
            </div>
            <div className={style.section}>
                <strong>A chapter of Prevent Child Abuse America</strong> &bull;{' '}
                <a href="//www.3cisd.com/privacy-policy" rel="noopener noreferrer" target="_blank">
                    Privacy Policy
                </a>
            </div>
        </div>
    );
};

export default Footer;
