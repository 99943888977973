import React from 'react';
import { LoginForm } from 'cccisd-laravel-nexus';
import { Link } from 'react-router-dom';
import _find from 'lodash/find';
import Style from './style.css';

var Fortress = window.cccisd.fortress;
var AppDefs = window.cccisd.appDefs;

const Welcome = () => {
    let homepageRoute = _find(AppDefs.routes, r => r.handle === AppDefs.navs[0].homepage);

    return (
        <div className={'row ' + Style.wrapper}>
            <div className="col-md-8">
                <h1>Welcome to PCANC</h1>
            </div>
            <div className="col-md-4">
                {Fortress.auth() ? (
                    <div className={Style.welcomeBox}>
                        <p>Hello, {Fortress.user.username}</p>
                        {homepageRoute && (
                            <Link to={homepageRoute.url}>
                                <button type="button" className="btn btn-primary">
                                    Go To Home
                                </button>
                            </Link>
                        )}
                    </div>
                ) : (
                    <LoginForm showPasswordToggle />
                )}
            </div>
        </div>
    );
};

export default Welcome;
