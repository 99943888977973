import React from 'react';
import { LoginForm } from 'cccisd-laravel-nexus';

const Boilerplate = window.cccisd.boilerplate;

const Login = () => {
    return (
        <div style={{ width: '450px', margin: 'auto' }}>
            <LoginForm
                bottomInstructions={
                    <>
                        Don&apos;t have an account? Register{' '}
                        <a href={Boilerplate.url('/register')} rel="noopener noreferrer">
                            here
                        </a>
                        .
                    </>
                }
            />
        </div>
    );
};

export default Login;
